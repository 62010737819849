import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { Typography, Link, Grid, TextField, Container, MenuItem, Button, FormControl, FormHelperText } from '@material-ui/core';
import { isMobileSize } from '../../helpers/functions/functions';

import {
	loginBankIDAction,
	checkBankIdProgress,
	bankIDQRGeneratorAction,
	handleSelectClientAction,
	resetBankIDProgress,
	loginAutostartBankIDAction,
	createSessionAction,
} from './actions/loginActions';
import { my_ssn_validations } from '../../helpers/functions/functions';

import { Refresh as RefreshIcon } from '@material-ui/icons';

import { Loading, Form } from '../Generic';

import QRCode from 'qrcode.react';
import styles from './BankIDv3.css';

class LoginBankID extends Component {
	constructor(props) {
		super(props);

		this.state = {
			autostarttoken: null,
			session_id: null,
			status: 'pending',
			QRImage: null,
		};

		this.pollSession = null;
	}
	componentDidMount() {
		this.props.resetBankIDProgress();

		this.createSessionAction();
	}

	createSessionAction = () => {
		this.props.createSessionAction({
			language: this.state.selectedLanguage,
			client_id: this.props.client.id,
			callback: (data) => {
				this.setState({
					QRImage: 'data:image/svg+xml;base64,' + data.QRCode,
					autoStartUrl: 'https://app.bankid.com/?autostarttoken=' + data.autoStartToken + '&redirect=',
					session_id: data.sessionId,
					status: data.status,
				});

				this.pollSession = setInterval(() => {
					if (data.sessionId) {
						this.props.checkBankIdProgress((data) => {
							if (data.status === 'failed') {
								clearInterval(this.pollSession);
								this.setState({ QRImage: null, status: 'failed' });
							} else {
								this.setState({
									QRImage: 'data:image/svg+xml;base64,' + data.QRCode,
									autoStartUrl: 'https://app.bankid.com/?autostarttoken=' + data.autoStartToken + '&redirect=',
									session_id: data.sessionId,
									status: data.status,
								});
							}
						}, this.props.type === 'login_meeting' && { user_type: 'meeting_access', meeting_token: this.props.meeting_token });
					} else {
						clearInterval(this.pollSession);
					}
				}, 3000);
			},
		});
	};

	componentWillUnmount() {
		clearInterval(this.pollSession);
	}

	state = {
		ssn: '',
		selectedLanguage: this.props.selectedLanguage,
		refreshQrcodeError: false,
	};

	render() {
		const { translate } = this.props;

		const hasQrcode = this.props.settings.site.supported_login_methods
			.replace(/,\s*$/, '')
			.split(',')
			.map((item) => item.trim())
			.some((item) => item == 'bankid_qrcode');

		return (
			<React.Fragment>
				{this.props.login.platform === 'backoffice' ? (
					<Container component="main" maxWidth="sm" className="generic-form-main-no-margin">
						<TextField
							fullWidth={true}
							select
							label={this.props.translate('login_select_client_backoffice')}
							value={this.props.login.client !== null && this.props.login.client.id}
							onChange={(value) => {
								this.props.handleSelectClientAction(
									this.props.settings.clients.find((client) => client.id === value.target.value),
									'backoffice',
									'bankID'
								);

								this.setState({
									refreshQrcodeError: false,
								});
							}}
							margin="normal"
							variant="outlined"
						>
							{this.props.settings.clients.map((client) => (
								<MenuItem key={client.id} value={client.id}>
									{client.name}
								</MenuItem>
							))}
						</TextField>
					</Container>
				) : null}
				<Grid container>
					<input id="deviceid" name="deviceid" type="hidden" />
					{this.props.type == 'login' && (
						<Grid item xs={12} md={12}>
							<hr className="generic-hr-text" data-content={translate('login_bankid_or_separator')} />
						</Grid>
					)}
					{isMobileSize() && (
						<Grid item xs={12} md={12}>
							<Button
								variant="contained"
								color={'primary'}
								href={this.state.autoStartUrl}
								// onClick={() => this.props.loginAutostartBankIDAction(this.state.selectedLanguage)}
							>
								{translate('login_bankid_start_app_button')}
							</Button>
						</Grid>
					)}
					{hasQrcode && !isMobileSize() && (
						<Grid item xs={12} md={12}>
							{this.state.QRImage !== null && <img src={this.state.QRImage} />}
						</Grid>
					)}
					{hasQrcode && !isMobileSize() && this.state.status === 'failed' && (
						<Grid item xs={12} md={12}>
							<Button
								color="secondary"
								variant="contained"
								size="small"
								className="login-bankid-button-refresh"
								onClick={(e) => {
									if (this.props.client !== null && this.props.client.id !== 0) {
										this.createSessionAction();
									}
								}}
							>
								{translate('login_bankid_button_refresh')}
								<RefreshIcon />
							</Button>
						</Grid>
					)}
					{this.props.type == 'login' && (
						<Grid item xs={12} md={12}>
							<Link
								href="#"
								variant="body2"
								onClick={() => {
									this.props.handleSelectClientAction(null, null);
								}}
							>
								&crarr; {translate('login_form_back_to_client_selection')}
							</Link>
						</Grid>
					)}
				</Grid>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	bankid_progress: state.login.bankid_progress,
	autostarttoken: state.login.autostarttoken,
	login: state.login,
	translate: getTranslate(state.localize),
	settings: state.settings,
	client: state.login.client,
});

export default connect(mapStateToProps, {
	loginBankIDAction,
	checkBankIdProgress,
	bankIDQRGeneratorAction,
	handleSelectClientAction,
	resetBankIDProgress,
	loginAutostartBankIDAction,
	createSessionAction,
})(LoginBankID);
